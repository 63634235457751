<template>
  <div class="content financial-statement-base">
    <div class="top-btns flex">
      <a-button @click="createSnapshot(1)" type="primary" :loading="createLoading">
        {{ $t(`生成快照`) }}
      </a-button>
      <a-button v-if="merchantList.length > 0" @click="createMergeSnapshot" type="primary">
        {{ $t(`生成合并快照`) }}
      </a-button>
    </div>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <SelectAccountSet
            :name.sync="formData.accountSetName"
            :code="formData.accountSetUnionCode"
            @change="changeAccountSet"
          />
        </a-col>
        <a-col :span="6">
          <a-input :disabled="true" :value="formData.businessUnitName" />
        </a-col>
        <a-col :span="6" v-if="merchantList.length > 0">
          <a-select
            v-model="formData.isMerge"
            style="width: 100%"
            :placeholder="$t(`是否包含子公司`)"
          >
            <a-select-option value="1">
              {{ $t(`包含子公司`) }}
            </a-select-option>
            <a-select-option value="0">
              {{ $t(`不包含子公司`) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            ref="templateSelectRef"
            api="getFinancialStatementTemplateList"
            :params="{
              businessUnitCode: formData.businessUnitCode,
              type: docType,
            }"
            :code.sync="formData.templateCode"
            :placeholder="$t(`报表模板`)"
          />
        </a-col>
        <a-col :span="6" @click="yearTimeOpen = true">
          <a-date-picker
            v-model="yearTime"
            :open="yearTimeOpen"
            :placeholder="$t('年份')"
            format="YYYY"
            mode="year"
            style="width: 100%"
            @panelChange="handleYearChange"
          />
        </a-col>
        <a-col :span="6">
          <!-- 月MONTH 季度QUARTER 年YEAR-->
          <CommonSelect
            :placeholder="$t('时间类型')"
            :list="$store.state.timeType"
            :code.sync="formData.time"
            @change="handleTimeTypeChange"
          />
        </a-col>
        <a-col :span="6" v-if="formData.time !== 'YEAR'">
          <CommonSelect
            :placeholder="$t('期间')"
            :list="sequenceOptions"
            :code.sync="formData.accountingSequence"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            {{ $t(`查询`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      v-show="showTable"
      class="no-padding-table"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :showSetting="false"
      :showPagination="false"
      :showNo="false"
      :tableProps="{
        bordered: true,
      }"
    >
      <template v-slot:content="{ text, record }">
        <div :class="'pl' + (record.level - 1) * 20">{{ text }}</div>
      </template>
      <template v-slot:currentPeriodAmount="{ record }">
        <PriceInput :value.sync="record.currentPeriodAmount" :propsStyle="propsStyle" />
      </template>
      <template v-slot:currentYearAmount="{ record }">
        <PriceInput :value.sync="record.currentYearAmount" :propsStyle="propsStyle" />
      </template>
      <template v-slot:lastPeriodAmount="{ record }">
        <PriceInput :value.sync="record.lastPeriodAmount" :propsStyle="propsStyle" />
      </template>
    </CommonTable>

    <a-modal
      :dialog-style="{ top: '20px' }"
      :title="$t('选择账套')"
      :visible="visible"
      :confirmLoading="confirmLoading"
      @cancel="cancelModal"
      @ok="onOk"
    >
      <CommonTable
        :columns.sync="accountSetColumns"
        :loading="accountSetLoading"
        :dataSource="accountSetList"
        :showSelect="true"
        :selectedRowKeys.sync="selectedRowKeys"
        :selectedRows.sync="selectedRows"
        :showSetting="false"
        :showPagination="false"
        @select="onSelectChange"
      ></CommonTable>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import SelectAccountSet from '@/component/selectAccountSet'
import { convertKeysToCamelCase } from '@/common'
import deepClone from '@/common/deepClone'

export default {
  name: 'BaseFinancialStatement',
  components: { SelectAccountSet },
  props: {
    docType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sequenceOptions: [],
      yearTime: moment(),
      yearTimeOpen: false,
      formData: {
        year: moment().format('YYYY'),
        accountSetCode: undefined,
        accountSetName: undefined,
        accountingSequence: undefined,
        type: this.docType,
        time: undefined,
        templateCode: undefined,
        isMerge: '0',
      },
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      columns: [],
      showTable: false,
      createLoading: false,
      accountSetList: [], //账套列表
      accountSetColumns: [],
      visible: false,
      accountSetLoading: false,
      confirmLoading: false,
    }
  },
  mounted() {
    this.setColumns()
  },
  computed: {
    ...mapState(['merchantList']),
    propsStyle() {
      return {
        border: 'none',
        textAlign: 'right',
      }
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    handleYearChange(value) {
      this.yearTime = value
      this.formData.year = moment(value).format('YYYY')
      this.yearTimeOpen = false
      this.$forceUpdate()
    },
    handleTimeTypeChange({ code: timeType }) {
      this.formData.accountingSequence = undefined
      if (timeType === 'MONTH') {
        this.sequenceOptions = Array.from({ length: 12 }, (_, i) => ({
          code: String(i + 1),
          name: `${i + 1}${this.$t('月')}`,
        }))
      } else if (timeType === 'QUARTER') {
        this.sequenceOptions = Array.from({ length: 4 }, (_, i) => ({
          code: String(i + 1),
          name: `${i + 1}${this.$t('季度')}`,
        }))
      } else {
        this.sequenceOptions = []
        this.formData.accountingSequence = 1
      }
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('项目名称'),
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
        },
        {
          title: this.$t('本期金额'),
          dataIndex: 'currentPeriodAmount',
          scopedSlots: { customRender: 'currentPeriodAmount' },
        },
        {
          title: this.$t('本年金额'),
          dataIndex: 'currentYearAmount',
          scopedSlots: { customRender: 'currentYearAmount' },
        },
        {
          title: this.$t('上期金额'),
          dataIndex: 'lastPeriodAmount',
          scopedSlots: { customRender: 'lastPeriodAmount' },
        },
      ]
      this.accountSetColumns = [
        {
          title: this.$t('账套名称'),
          dataIndex: 'name',
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'business_unit_name',
        },
      ]
    },
    searchHandle() {
      if (!this.formData.businessUnitCode) return
      if (!this.formData.templateCode) return this.$message.warning(this.$t('请选择报表模板'))
      if (!this.formData.time) return this.$message.warning(this.$t('请选择时间类型'))
      if (!this.formData.accountingSequence) return this.$message.warning(this.$t('请选择时间期间'))
      this.getList()
      this.showTable = true
    },
    async getList(needConfirm = 1) {
      this.loading = true
      const data = deepClone(this.formData)
      data.isMerge = Number(data.isMerge)
      const url = api.getFinancialStatementSnapshot
      const res = await http({
        url,
        data,
      })
      let result = convertKeysToCamelCase(res.result?.detail_list || [])
      this.tableData = result
      if (needConfirm && result?.length === 0) {
        //提示当前期间没有快照 是否创建
        this.$confirm({
          title: this.$t('提示'),
          content: this.$t('当前期间没有快照，是否需要创建?'),
          okText: this.$t('确定'),
          cancelText: this.$t('取消'),
          onOk: async () => {
            await this.createSnapshot()
          },
        })
      }
      this.loading = false
      return result
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
      this.$nextTick(() => {
        this.$refs.templateSelectRef.getList()
      })
    },
    async createSnapshot(needCheck = 0) {
      if (needCheck) {
        const list = await this.getList(0)
        if (list.length > 0) {
          this.$confirm({
            title: this.$t('提示'),
            content: this.$t('当前期间快照已存在，重新创建会覆盖已有，是否重新创建?'),
            okText: this.$t('确定'),
            cancelText: this.$t('取消'),
            onOk: async () => {
              await this.realCreateSnapshotHandle()
            },
          })
          return
        }
      }
      this.realCreateSnapshotHandle()
    },
    async realCreateSnapshotHandle() {
      this.createLoading = true
      const data = deepClone(this.formData)
      data.isMerge = Number(data.isMerge)
      try {
        await http({
          url: api.generateFinancialStatementSnapshot,
          data,
        })
        this.$message.success(this.$t('快照生成成功'))
        this.searchHandle()
      } catch (e) {
        console.log(e)
      } finally {
        this.createLoading = false
      }
    },
    createMergeSnapshot() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.getAccountList()
      this.visible = true
    },
    async getAccountList() {
      this.accountSetLoading = true
      const res = await http({
        url: api.getAccountSetList,
        data: {
          merchantCodeList: this.merchantList.map((item) => item.code),
        },
      })
      let list = res.result.list || res.result || []
      this.accountSetList = list
      this.accountSetLoading = false
    },
    cancelModal() {
      this.visible = false
    },
    async onOk() {
      if (this.selectedRows.length === 0) {
        return this.$message.warning(this.$t('请选择账套'))
      }
      const data = deepClone(this.formData)
      data.isMerge = Number(data.isMerge)
      const res = await http({
        url: api.mergeFinancialStatementSnapshot,
        data: {
          ...data,
          accountSetList: this.selectedRows.map((item) => ({
            businessUnitCode: item.business_unit_code,
            code: item.code,
            merchantCode: item.merchant_code,
          })),
        },
      })
      if (res?.success) {
        this.$message.success(this.$t('合并快照成功'))
        this.cancelModal()
        this.getList()
      }
    },
  },
}
</script>

<style scoped>
.no-padding-table :deep(td),
.no-padding-table :deep(th) {
  padding: 0 !important;
}

.no-padding-table :deep(th) {
  text-align: center;
}
</style>
